import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { FieldTypeConfig } from "@ngx-formly/core";
import { FieldType } from "@ngx-formly/material/form-field";

@Component({
  selector: "lib-formly-textarea-type",
  templateUrl: "./formly-textarea-type.component.html",
  styleUrls: ["./formly-textarea-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyTextareaTypeComponent extends FieldType<FieldTypeConfig> {
  textAreaErrorMatcher = new TextAreaErrorMatcher();
  constructor() {
    super();
  }
  onPaste(event: ClipboardEvent): void {
    if (this.props.preventPaste) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const pastedText = event.clipboardData?.getData("text") || "";

      const textarea = event.target as HTMLTextAreaElement;
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;

      if (startPos === 0 && endPos === this.formControl.value?.length) {
        // Replace the entire content if all text is selected
        this.formControl.setValue(pastedText);
      } else {
        // Insert pasted text at the cursor position
        const before = this.formControl.value?.substring(0, startPos) || "";
        const after = this.formControl.value?.substring(endPos) || "";
        const completedTxt = `${before}${pastedText}${after}`;
        this.formControl.setValue(completedTxt);
      }
      event.preventDefault();
    }
  }

  preventEnter(event: Event): void {
    if (this.props.preventEnter) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}

export class TextAreaErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null): boolean {
    return !!(control?.invalid && (control?.dirty || control?.touched));
  }
}
