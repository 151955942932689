import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { EditorModule } from "@tinymce/tinymce-angular";
import { FlexModule } from "@vp/shared/directives/flex";
import { AddOrEditSnippetDialogComponent } from "./add-or-edit-snippet-dialog.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    AngularEditorModule,
    FlexModule,
    EditorModule
  ],
  exports: [AddOrEditSnippetDialogComponent],
  declarations: [AddOrEditSnippetDialogComponent]
})
export class AddOrEditSnippetDialogModule {}
