import { Injectable } from "@angular/core";
import { PatientConsoleDeviceData, SirenData, User } from "@vp/models";
import { FeatureService } from "@vp/shared/features";
import { deepCopy } from "@vp/shared/utilities";
import { createPatch } from "rfc6902";
import { map } from "rxjs";
import { UserApiService } from "../api/user-api.service";

export const defaultDeviceConfigurationSettings: Record<string, string> = {
  dimmedDisplayStartTime: "19:00",
  dimmedDisplayEndTime: "07:00",
  dimmedDisplayBrightness: "5",
  dimmedDisplayIntensity: "5",
  minimumVolume: "0.5",
  defaultVolume: "0.6",
  logFileUploadEnabled: "true",
  alertFrequencyInSeconds: "4000",
  lowBatteryAlertVolume: "50",
  minimumBatteryLevel: "25",
  quietHoursStartTime: "19:00",
  quietHoursEndTime: "07:00",
  patientMessageFontColor: "#32CD32",
  messageCenterDefaultFontSize: "50",

  resetCameraPositionAfterCall: "true",
  messageCenterEnabled: "true"
};

const VIRTUAL_CARE_FEATURE_ID = "virtualCare";
const VIRTUAL_CARE_DEFAULT_SETTINGS = "defaultSettings";

@Injectable({
  providedIn: "root"
})
export class DeviceService {
  constructor(
    private readonly featureService: FeatureService,
    private readonly userApiService: UserApiService
  ) {}

  defaultDeviceSettings$ = this.featureService
    .patientConsoleSettingsValue$(VIRTUAL_CARE_FEATURE_ID, VIRTUAL_CARE_DEFAULT_SETTINGS)
    .pipe(map(defaultDeviceSettings => defaultDeviceSettings.settings as Record<string, string>));

  getDeviceSetting$ = (settingName: string, device: User) => {
    return this.defaultDeviceSettings$.pipe(
      map(defaultSetting => {
        const deviceSetting = device?.patientConsoleDeviceData?.applicationSettings as Record<
          string,
          string
        >;

        const settingValue =
          deviceSetting?.[settingName] ||
          defaultSetting?.[settingName] ||
          defaultDeviceConfigurationSettings[settingName];
        return settingValue;
      })
    );
  };

  toggleDeviceSiren(device: User) {
    const clone = deepCopy(device) as User;
    clone.patientConsoleDeviceData = {
      ...(device.patientConsoleDeviceData || {}),
      sirenData: {
        ...(device?.patientConsoleDeviceData?.sirenData || {}),
        sirenStatus: !device?.patientConsoleDeviceData?.sirenData?.sirenStatus
      } as SirenData
    } as PatientConsoleDeviceData;
    const operations = createPatch(device, clone);
    return this.userApiService.patch(device.userId, operations);
  }
}
