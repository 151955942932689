import { EventBase, SirenData } from "@vp/models";

export interface DeviceSirenStatusChangedNotification {
  deviceId: string;
  sirenData: SirenData;
}

export class DeviceSirenStatusChangedEvent extends EventBase<DeviceSirenStatusChangedNotification> {
  constructor(notification: DeviceSirenStatusChangedNotification) {
    super();
    this.args = notification;
  }
}
