import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { CaseData, CaseFile, CaseFilePdfExtract } from "@vp/models";
import { API_BASE_URL } from "@vp/shared/tokens";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export type FileUploadType = "images" | "documents" | "video";

@Injectable({
  providedIn: "root"
})
export class FileApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private readonly http: HttpClient
  ) {}

  createUploadURL = (caseId: string) => `${this.apiBaseUrl}/case/${caseId}/dicom-images-upload`;
  sendUploadURL = (caseId: string) => (uploadId: string) =>
    `${this.apiBaseUrl}/case/${caseId}/dicom-images-upload/${uploadId}`;
  queueUploadURL = (caseId: string) => (uploadId: string) =>
    `${this.apiBaseUrl}/case/${caseId}/dicom-images-upload/${uploadId}/queue`;
  uploadFileURL = (caseId: string) => `${this.apiBaseUrl}/case/${caseId}/file`;

  deleteFile = (
    caseId: string,
    fileType: FileUploadType,
    fileName: string
  ): Observable<boolean> => {
    const apiURL = `${
      this.apiBaseUrl
    }/case/${caseId}/file/fileType/${fileType}/fileName/${encodeURIComponent(fileName)}`;

    return this.http
      .delete(apiURL, {
        observe: "response"
      })
      .pipe(
        map(response => {
          if (response.status === 200) {
            return true;
          }
          return false;
        })
      );
  };

  renameFile = (caseId: string, fileType: FileUploadType, file: CaseFile): Observable<boolean> => {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/file/${fileType}/rename`;

    return this.http
      .post(apiURL, file, {
        observe: "response"
      })
      .pipe(
        map(response => {
          if (response.status === 200) {
            return true;
          }
          return false;
        })
      );
  };

  getPdfPageCount = (caseId: string, fileName: string): Observable<number | null> => {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/pdf/${encodeURIComponent(
      fileName
    )}/pageCount`;

    return this.http
      .get(apiURL, {
        observe: "response"
      })
      .pipe(
        map(response => {
          if (response.status === 200) {
            return response.body as number;
          }
          return null;
        })
      );
  };

  extractPdfPages = (
    caseId: string,
    pdfExtract: CaseFilePdfExtract
  ): Observable<CaseFile | null> => {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/pdf/extract`;

    return this.http
      .post(apiURL, pdfExtract, {
        observe: "response"
      })
      .pipe(
        map(response => {
          if (response.status === 200) {
            return response.body as CaseFile;
          }
          return null;
        })
      );
  };

  updateImageData(caseId: string, imageData: Record<string, unknown>): Observable<CaseData> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/imagedata`;
    return this.http.put<CaseData>(apiURL, imageData);
  }

  updateDocumentData(caseId: string, documentData: Record<string, unknown>): Observable<CaseData> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/documentdata`;
    return this.http.put<CaseData>(apiURL, documentData);
  }

  downloadDocument(caseId: string, blobName: string) {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/fileDownload/${blobName}`;
    return this.http.get<Blob>(apiURL, { responseType: "blob" as "json" });
  }

  getReadOnlySecurelink(caseId: string | undefined, fileName: string, isImage: boolean) {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/document-secure-link/${encodeURIComponent(
      fileName
    )}/${isImage}`;
    return this.http.get<string>(apiURL, { responseType: "text" as "json" });
  }

  getReadOnlyImageSecurelinks(
    caseId: string | undefined,
    imageFileNames: string[]
  ): Observable<string[]> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/image-secure-links`;
    return this.http.put<string[]>(apiURL, imageFileNames);
  }
}
